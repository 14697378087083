<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header :data-background-color="getTheme">
          <h4 class="title">Contacts</h4>
        </md-card-header>
        <md-card-content>
          <contact-table :table-header-color="getTheme"></contact-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import ContactTable from "../components/Contacts/ContactTable";
import { mapGetters } from "vuex";

export default {
  components: {
    ContactTable,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>