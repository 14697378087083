<template>
  <div class="contact-details">
    <h1>Selected message:</h1>
    <p>Name: {{ contact.name }}</p>
    <p>Email: {{ contact.email }}</p>
    <p>Creado en: {{ contact.timestamp }}</p>
    <p>Message:</p>
    <p class="message">{{ contact.message }}</p>
  </div>
</template>

<script>
export default {
  props: ["contact"],
};
</script>