<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50">
        <md-field class="filter-input">
          <label>Filter</label>
          <md-input @keyup="getItems" v-model="filter"></md-input>
        </md-field>
      </div>
    </div>
    <md-table v-if="data.data" v-model="data.data" @md-selected="onSelect" :table-header-color="tableHeaderColor">
      <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="single">
        <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
        <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
        <md-table-cell md-label="Timestamp">{{ item.timestamp }}</md-table-cell>
      </md-table-row>
    </md-table>
    <pagination :data="data" @pagination-change-page="getItems"></pagination>
    <contact-details v-if="item" :contact="item" />
  </div>
</template>

<script>
import ContactDetails from './ContactDetails';
export default {
  name: "contact-table",
  components: {
      ContactDetails,
  },
  props: {
    tableHeaderColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      data: {},
      filter: '',
      item: null,
    };
  },
  methods: {
    getItems(page = 1){
        this.$store.dispatch('contact/getContacts', {page, filter: this.filter})
        .then((items) => {
            this.data = items;
        });
    },
    onSelect(item) {
        this.item = item;
    },
  },
  mounted(){
    this.getItems();
  }
};
</script>